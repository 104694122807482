<template>
  <article id="wrap">

      <form>

        <AppHeader theme="green">
          <template v-slot:left>
            <a @click="$router.go(-1)"><img src="@/assets/img/icon_back.png" alt=""></a>
          </template>
          入力内容確認
        </AppHeader>

        <div class="confirm_item_total">
          <div class="item_shipping">合計(税込)</div>
          <div class="item_price">{{ totalPrice | currency }}</div>
        </div><!-- .item_total -->

        <hr class="item_hr">

        <section v-if="!isDigitalItem">
          <div class="ta_c txt_16 bold mt_20 mb_10">お客様情報</div>
          <table class="table01">
            <tbody>
            <tr v-if="!isDigitalItem">
              <th>お名前</th>
              <td>{{ last_name }} {{ first_name }}</td>
            </tr>
            <tr>
              <th>フリガナ</th>
              <td>{{ last_kana }} {{ first_kana }}</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>{{ tel }}</td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>{{ zip }}</td>
            </tr>
            <tr>
              <th>都道府県</th>
              <td>{{ prefecture }}</td>
            </tr>
            <tr>
              <th>市区郡</th>
              <td>{{ city }}</td>
            </tr>
            <tr>
              <th>町村・番地・建物名</th>
              <td>{{ street }}</td>
            </tr>
            </tbody>
          </table>
        </section>

        <hr class="item_hr" v-if="!isDigitalItem">

        <section class="contents pb_150">
          <div class="bt_form">
<!--            <button type="button" @click="next">送信</button>-->
            <PaymentButton interval="month" title="決済へ進む"
                             :onTokenCreatedSubscription="onTokenCreated"
                             :onTokenFailedSubscription="onTokenFailed"></PaymentButton>
          </div>
        </section><!--.contents-->

        <AppFooter></AppFooter>

      </form>

    </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import PaymentButton from '@/components/PaymentButton';
export default {
  components: { AppHeader, AppFooter, PaymentButton},
  props: {
    user_id: Number,
    artist_id: Number,
    code: String,
    price: Number,
    last_name: String,
    first_name: String,
    last_kana: String,
    first_kana: String,
    email: String,
    gender: String,
    birthday: String,
    zip: String,
    prefecture: String,
    city: String,
    street: String,
    building: String,
    tel: String,
    message: String,
  },
  computed: {
    items () {
      return this.$store.state.cart;
    },
    totalPrice () {
      let price = 0;
      this.items.forEach(item => {
        if (this.isDigitalItem) {
          price += Number(item.price);
        } else {
          price += Number(item.price) * Number(item.cartValue);
        }
      })
      return price;
    },
    isDigitalItem () {
      return this.items[0].type && this.items[0].type === 'digital';
    }
  },
  methods: {
    async next () {
      const params = {
        user_id: this.user_id,
        artist_id: this.artist_id,
        price: this.price,
        last_name: this.last_name,
        first_name: this.first_name,
        last_kana: this.last_kana,
        first_kana: this.first_kana,
        email: this.email,
        zip: this.zip,
        prefecture: this.prefecture,
        city: this.city,
        street: this.street,
        building: this.building,
        tel: this.tel,
      }
      try {
        const response = await this.$http.post(`/orders`, params);
        console.log(response);
      } catch (e) {
        this.$swal('', '失敗しました', 'error');
        return;
      }
      this.$router.push({name: 'cart-send'});
    },

    async onTokenCreated(token=null) {
      // return new Promise( (resolve, reject) => {
      if (!token) token = document.getElementsByName('payjp-token')[0].value;
      // this.$emit('update:card_token', token);
      this.$emit('pay', token);
    },
    onTokenFailed() {
      console.log('fails');
      // 処理
      this.$toasted.error('決済に失敗しました', {
        position: 'top-center',
        duration: 2000,
      });
    },
  }
}
</script>